import React, {useEffect, useState} from "react";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import {Link} from "react-router-dom";
import {
    Box,
    Button,
    Card,
    Checkbox,
    FormControlLabel,
    InputAdornment,
    Select,
    makeStyles,
    MenuItem,
    TextField,
    Typography,
    LinearProgress
} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Routes from "router/Routes";
import DataTable from "components/tables/DataTable";
import MobileDataTable from "components/tables/MobileDataTable";
import * as Actions from "store/actions/PatientAction";

import PlusIcon from "assets/images/icons/plus.svg";
import SearchIcon from "assets/images/icons/search.svg";
import ArrowLeftIcon from "assets/images/icons/arrow-left.svg";
import ArrowRightIcon from "assets/images/icons/arrow-right.svg";
import Title from "components/text/Title";
import API from "../../apis/API";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "22px 32px",
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(2, 0, 10),
            background: "#FFF",
        },
    },
    toolbar: {
        padding: theme.spacing(0, 2),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 26,
        "& .MuiButton-root": {
            padding: "0 24px",
            height: 56,
            background: "#003366",
            borderRadius: 8,
            "& .MuiTypography-root": {
                fontSize: 16,
                fontWeight: 700,
                textTransform: "none",
                color: "#FFF",
            },
            "& img": {
                filter: "brightness(100) invert(0)",
                marginRight: 20,
            },
        },
        [theme.breakpoints.down("md")]: {
            marginBottom: 16,
            "& .MuiButton-root": {
                zIndex: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 68,
                height: 68,
                borderRadius: 50,
                position: "fixed",
                right: 16,
                bottom: 88,
                padding: 0,
                "& .MuiTypography-root": {
                    display: "none",
                },
                "& img": {
                    width: 36,
                    marginRight: "0px!important",
                },
            },
        },
    },
    card: {
        background: "#FFF",
        border: "1px solid #DDE3EE",
        borderRadius: 8,
        boxShadow: "none",
        [theme.breakpoints.down("md")]: {
            border: "none",
        },
    },
    cardToolbar: {
        padding: 24,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #DDE3EE",
        "& > .MuiBox-root": {
            display: "flex",
            alignItems: "center",
            "& > .MuiTypography-root": {
                fontWeight: 700,
                fontSize: 20,
                color: "#003366",
                marginRight: 16,
            },
            "& > .MuiTextField-root": {
                width: "100%",
                minWidth: 343,
                maxWidth: 360,
                borderRadius: 8,
                "& .MuiInputBase-input": {
                    fontSize: 16,
                },
                background: "#F7F8FB",
                "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #DDE3EE",
                    borderRadius: 8,
                },
            },
            "& > .MuiButton-root": {
                padding: "0 30px",
                height: 50,
                background: "#F7F8FB",
                border: "1px solid #DDE3EE",
                borderRadius: 10,
                marginLeft: 8,
                fontSize: 18,
                fontWeight: 500,
                textTransform: "none",
                letterSpacing: "-0.4px",
                color: "#003366",
                [theme.breakpoints.between(1280, 1400)]: {
                    fontSize: 11,
                },
            },
        },
        [theme.breakpoints.down("md")]: {
            flexWrap: "wrap",
            padding: theme.spacing(0, 2),
            "& > .MuiBox-root": {
                width: '100%',
                flexWrap: "wrap",
                "&:first-child": {
                    marginBottom: 12,
                    "& > .MuiTextField-root": {
                        maxWidth: "unset",
                        minWidth: "unset",
                    },
                },
                "& > .MuiButton-root": {
                    marginBottom: 12,
                    marginLeft: "0!important",
                    padding: "0!important",
                    width: "100%",
                },
                "& > .MuiTypography-root": {
                    display: "none",
                },
            },
        },
    },
    filterToolbar: {
        padding: "13px 32px 16px",
        borderBottom: "1px solid #DDE3EE",
        "& > .MuiTypography-root": {
            fontSize: 20,
            color: "#003366",
            fontWeight: 700,
            marginBottom: 3,
        },
        "& > .MuiBox-root": {
            display: "flex",
        },
        [theme.breakpoints.down("md")]: {
            "& > .MuiBox-root": {
                flexDirection: "column",
            },
        },
    },
    formControl: {
        width: 392,
        marginRight: 24,
        "& .MuiInputBase-root": {
            width: "100%",
            "& .MuiSelect-root": {
                fontSize: 16,
                color: "#22272F",
            },
            "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #A6AEBF",
                borderRadius: 8,
            },
        },
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    formLabel: {
        fontSize: 14,
        color: "#6C7689",
        marginBottom: 2,
    },
    checkbox: {
        display: "flex",
        alignItems: "center",
        paddingTop: 20,
        "& .MuiTypography-root": {
            fontWeight: 700,
            fontSize: 16,
            color: "#22272F",
        },
    },
    cardFooter: {
        padding: theme.spacing(3, 4),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down("md")]: {
            justifyContent: "flex-end",
            padding: theme.spacing(2),
            borderBottom: "1px solid #DDE3EE",
        },
    },
    limitSelectBox: {
        display: "flex",
        alignItems: "center",
        "& > .MuiTypography-root": {
            fontSize: 14,
            color: "#454D5F",
            marginRight: 6,
        },
        "& .MuiSelect-root": {
            display: "flex",
            alignItems: "center",
            width: 60,
            height: 40,
            color: "#181819",
            fontSize: 14,
        },
        "& .MuiOutlinedInput-input": {
            padding: theme.spacing(0, 1),
            boxSizing: "border-box",
        },
    },
    pagination: {
        display: "flex",
        alignItems: "center",
        "& .MuiButton-root": {
            minWidth: 24,
            width: 24,
            height: 24,
            background: "#F7F8FB",
            border: "1px solid #DDE3EE",
            "& img": {
                width: 7,
            },
        },
        "& .MuiTypography-root": {
            fontSize: 14,
            color: "#454D5F",
            margin: "0 10px",
        },
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
}));

const PatientList = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {criteriaGroups, medicines, patientList} = useSelector((state) => {
        return {
            patientList: state.patient.patientList,
            medicines: state.patient.medicineList,
            criteriaGroups: state.patient.criteriaGroupList,
        };
    }, shallowEqual);

    const [orderBy, setOrderBy] = useState(null);
    const [isShowFilterToolbar, setIsShowFilterToolbar] = useState(false);
    const [filter, setFilter] = useState({
        limit: 20,
        offset: 0,
        order_by: "id",
        order_direction: "ASC",
        search: "",
        sub_groups: "",
        groups: "",
        medicines: "",
        is_finished: ""
    });

    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(0);
    const [group, setGroup] = useState([]);
    const [selectedMeds, setSelectedMeds] = useState([]);
    const [selectedDoctors, setSelectedDoctors] = useState([]);
    const [doctors, setDoctors] = useState([]);

    const handleChangeGroup = (key) => {
        let clonedGroup = JSON.parse(JSON.stringify(group));
        clonedGroup.includes(key)
            ? clonedGroup.splice(clonedGroup.indexOf(key), 1)
            : clonedGroup.push(key);

        setGroup(clonedGroup);
    };

    const renderSelectedGroupNames = () => {
        let renderTxt = "";
        let clonedGroups = {};

        const subGroups = group.filter((item) => item.indexOf("subgroup") > -1);
        subGroups.map((subGroup) => {
            const {groupNR, subGroupName} = getGroupInfo(subGroup.slice(9) * 1);
            let clonedSubGroups = clonedGroups[groupNR] ? clonedGroups[groupNR] : [];
            clonedSubGroups.push(subGroupName);
            clonedGroups = {...clonedGroups, [groupNR]: clonedSubGroups};
        });

        group.map((singleGroup) => {
            if (singleGroup.startsWith('group')) {
                const {groupNR, groupName} = getMainGroupInfo(parseInt(singleGroup.slice(6)));
                renderTxt += "Grupa " + groupNR + " - " + groupName + ", ";
            }
        });

        Object.keys(clonedGroups)
            .sort()
            .map((groupKey) => {
                renderTxt += `Grupa ${groupKey} - `;
                clonedGroups[groupKey].map((subGroupName) => {
                    renderTxt += `${subGroupName}, `;
                });
            });

        return renderTxt.slice(0, -2);
    };

    const getMainGroupInfo = (id) => {
        let groupNR = null;
        let groupName = null;

        criteriaGroups.map((group) => {
            if (group.id === id) {
                groupNR = group.nr;
                groupName = group.name;
            }
        });

        return {groupNR, groupName};
    };

    const getGroupInfo = (id) => {
        let groupNR = null;
        let subGroupName = null;
        criteriaGroups.map((group) => {
            group.sub_groups.map((subGroup) => {
                if (subGroup.id === id) {
                    groupNR = group.nr;
                    subGroupName = subGroup.name;
                }
            });
        });

        return {groupNR, subGroupName};
    };

    const handleChangeKeyword = (e) => {
        setFilter({
            ...filter,
            search: e.target.value,
        });
    };

    const handleChangeLimit = (e) => {
        setFilter({
            ...filter,
            limit: e.target.value,
        });
    };

    const handleChangeIsFinished = (e) => {
        setFilter({
            ...filter,
            is_finished: !filter.is_finished,
        });
    };

    const handleChangePagination = (flag) => {
        if (flag === "previous") {
            filter.offset !== 0 &&
            setFilter({...filter, offset: filter.offset - 1});
        } else {
            filter.offset !== Math.ceil(patientList.total_count / filter.limit) - 1 &&
            setFilter({...filter, offset: filter.offset + 1});
        }
    };

    const handleChangeSort = (flag) => {
        setOrderBy(orderBy ? (orderBy === "ASC" ? "DESC" : "ASC") : "ASC");
        setFilter({
            ...filter,
            order_by: flag,
            order_direction: orderBy,
        });
    };

    const handleChangeMedicine = (med) => {
        if (!!selectedMeds?.find(selected => selected?.id === med.id)) {
            setSelectedMeds(selectedMeds?.filter(selected => selected?.id !== med.id));
            return;
        }

        setSelectedMeds([...selectedMeds, med]);
    };

    const renderMedsValue = () => {
        let preparedString = '';

        selectedMeds?.forEach((selected, index, arr) => {
            let lastIndex = index === arr.length - 1;

            if (selectedMeds?.length > 1 && !lastIndex) {
                preparedString += selected?.name + ', ';
                return;
            }

            return preparedString += selected?.name + '.';
        });

        return preparedString;
    };


    const renderDoctorsValue = () => {
        let preparedString = "";

        selectedDoctors?.forEach((selected, index, arr) => {
            let lastIndex = index === arr.length - 1;

            if (selectedDoctors?.length > 1 && !lastIndex) {
                preparedString += selected?.firstname + " " + selected?.surname + ", ";
                return;
            }

            return (preparedString +=
                selected?.firstname + " " + selected?.surname + ".");
        });

        return preparedString;
    };

    const handleChangeDoctor = (doctor) => {
        if (!!selectedDoctors?.find((selected) => selected?.id === doctor.id)) {
            setSelectedDoctors(
                selectedDoctors?.filter((selected) => selected?.id !== doctor.id)
            );
            return;
        }

        setSelectedDoctors([...selectedDoctors, doctor]);
    };

    useEffect(() => {
        dispatch(Actions.getMedicineList());
        dispatch(Actions.getCriteriaGroupList());

        API.doctors.all().then((res) => setDoctors(res.data?.data));

        window.scrollTo(0, 0);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let clonedSubGroups = [];

        group.map((item) => {
            if (item.indexOf("subgroup") > -1) {
                clonedSubGroups.push(item.slice(9));
            }
        });

        let clonedGroups = [];

        group.map((item) => {
            if (item.startsWith("group")) {
                clonedGroups.push(item.slice(6));
            }
        });


        setFilter({
            ...filter,
            sub_groups: clonedSubGroups.join(","),
            groups: clonedGroups.join(",")
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [group]);

    useEffect(() => {
        dispatch(Actions.getPatientList(filter));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    useEffect(() => {
        setStartIndex(filter.limit * filter.offset + 1);
        setEndIndex(
            filter.limit * (filter.offset + 1) > patientList.total_count
                ? patientList.total_count
                : filter.limit * (filter.offset + 1)
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientList]);

    useEffect(() => {
        setFilter({...filter, medicines: selectedMeds?.map(med => med.id).join(",")});
    }, [selectedMeds]);

    useEffect(() => {
        setFilter({...filter, doctors: selectedDoctors?.map(med => med.id).join(",")});
    }, [selectedDoctors]);

    return (
        <Box className={classes.root}>
            <Box className={classes.toolbar}>
                <Title>{t('patients.list')} ({patientList.total_count})</Title>
                <Link
                    to={Routes.Patients.Create}
                    style={{textDecoration: "none"}}
                >
                    <Button>
                        <img src={PlusIcon} alt=":( Not Found"/>
                        <Typography>{t('patients.add')}</Typography>
                    </Button>
                </Link>
            </Box>
            <Card className={classes.card}>
                <Box className={classes.cardToolbar}>
                    <Box>
                        <Typography>{t('patients.search')}:</Typography>
                        <TextField
                            variant="outlined"
                            placeholder={t('patients.enter_patient_detail')}
                            value={filter.keyword}
                            onChange={(e) => handleChangeKeyword(e)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={SearchIcon} alt=":( Not Found"/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box>
                        {/* <Button>Generuj raport z zastosowanymi filtrami</Button> */}
                        {/* <Button>Generuj raport</Button> */}
                        <Button
                            onClick={() => setIsShowFilterToolbar(!isShowFilterToolbar)}
                        >
                            {isShowFilterToolbar ? <>Zamknij filtry</> : <>Otwórz filtry</>}
                        </Button>
                    </Box>
                </Box>
                {isShowFilterToolbar && (
                    <Box className={classes.filterToolbar}>
                        <Typography>Filtry:</Typography>
                        <Box>
                            <Box className={classes.formControl}>
                                <Typography className={classes.formLabel}>Leki:</Typography>
                                <Select
                                    value={selectedMeds}
                                    multiple
                                    variant="outlined"
                                    renderValue={renderMedsValue}
                                >
                                    {medicines.map((med) => {
                                        return (
                                            <MenuItem key={med.id} value={med.id}
                                                      onClick={() => handleChangeMedicine(med)}>
                                                <Checkbox
                                                    color="primary"
                                                    checked={!!selectedMeds?.find(selectedMed => selectedMed.id === med.id)}
                                                />
                                                <Typography>
                                                    {med.name}
                                                </Typography>
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Box>
                            <Box className={classes.formControl}>
                                <Typography className={classes.formLabel}>
                                    Grupy i podgrupy:
                                </Typography>
                                <Select
                                    value={group}
                                    variant="outlined"
                                    multiple
                                    renderValue={() => renderSelectedGroupNames()}
                                >
                                    {criteriaGroups.map((g) => {
                                        return (
                                            <React.Fragment key={g.id}>
                                                <MenuItem
                                                    onClick={() => handleChangeGroup(`group-${g.id}`)}
                                                >
                                                    <Checkbox
                                                        color="primary"
                                                        checked={group.indexOf(`group-${g.id}`) > -1}
                                                    />
                                                    <Typography>
                                                        Group {g.nr} - {g.name}
                                                    </Typography>
                                                </MenuItem>
                                                {g.sub_groups &&
                                                    g.sub_groups.map((sg) => {
                                                        return (
                                                            <MenuItem
                                                                onClick={() =>
                                                                    handleChangeGroup(`subgroup-${sg.id}`)
                                                                }
                                                                key={`subgroup-${sg.id}`}
                                                                style={{paddingLeft: 32}}
                                                            >
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={
                                                                        group.indexOf(`subgroup-${sg.id}`) > -1
                                                                    }
                                                                />
                                                                <Typography>
                                                                    {sg.name} - {sg.description}
                                                                </Typography>
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </React.Fragment>
                                        );
                                    })}
                                </Select>
                            </Box>
                            <Box className={classes.formControl}>
                                <Typography className={classes.formLabel}>{t("doctor")}</Typography>
                                <Select
                                    value={selectedDoctors}
                                    multiple
                                    variant="outlined"
                                    renderValue={renderDoctorsValue}
                                >
                                    {doctors.map((doctor) => {
                                        return (
                                            <MenuItem
                                                key={doctor.id}
                                                value={doctor.id}
                                                onClick={() => handleChangeDoctor(doctor)}
                                            >
                                                <Checkbox
                                                    color="primary"
                                                    checked={
                                                        !!selectedDoctors?.find(
                                                            (selectedDoctor) => selectedDoctor.id === doctor.id
                                                        )
                                                    }
                                                />
                                                <Typography>
                                                    {doctor.firstname + " " + doctor.surname}
                                                </Typography>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Box>
                            <Box className={classes.checkbox}>
                                <FormControlLabel
                                    control={<Checkbox color="primary"/>}
                                    checked={filter.is_finished}
                                    name="is_finished"
                                    value={true}
                                    label="Zakończenie udziału w programie"
                                    onChange={handleChangeIsFinished}
                                />
                            </Box>
                        </Box>
                    </Box>
                )}
                {!patientList.data ? <LinearProgress/> : <DataTable
                    rows={patientList.data || []}
                    handleSort={(flag) => handleChangeSort(flag)}
                />}
                <Box className={classes.cardFooter}>
                    <Box className={classes.limitSelectBox}>
                        <Typography>{t('per_page_count')}: </Typography>
                        <Select
                            variant="outlined"
                            value={filter.limit}
                            onChange={(e) => handleChangeLimit(e)}
                        >
                            <MenuItem value="10">10</MenuItem>
                            <MenuItem value="20">20</MenuItem>
                            <MenuItem value="50">50</MenuItem>
                            <MenuItem value="100">100</MenuItem>
                        </Select>
                    </Box>
                    <Box className={classes.pagination}>
                        <Button onClick={() => handleChangePagination("previous")}>
                            <img src={ArrowLeftIcon} alt=":( Not Found"/>
                        </Button>
                        <Typography>
                            {startIndex}-{endIndex} {t('of')} {patientList.total_count}
                        </Typography>
                        <Button onClick={() => handleChangePagination("next")}>
                            <img src={ArrowRightIcon} alt=":( Not Found"/>
                        </Button>
                    </Box>
                </Box>
                <MobileDataTable rows={patientList.data || []}/>
            </Card>
        </Box>
    );
};

export default PatientList;
